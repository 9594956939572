import { dev } from '$app/environment'
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

if (!dev) {
	Sentry.init({
		dsn: 'https://b56de48dfbde3bf5d4b03164969dd418@o4506758153175040.ingest.sentry.io/4506758155272192',
		// If you don't want to use Session Replay, just remove the line below:
		integrations: [replayIntegration()],
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		tracesSampleRate: 1.0,
	})
}
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
