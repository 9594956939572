import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,6,2];

export const dictionary = {
		"/": [~8],
		"/admin": [~10,[4]],
		"/admin/comments": [11,[4]],
		"/admin/login": [12,[4]],
		"/admin/logout": [~13,[4]],
		"/calendar/byo": [14],
		"/calendar/tee": [15,[],[5]],
		"/contact": [16],
		"/drafts": [17],
		"/emojivore": [18,[6],[7]],
		"/emojivore/order": [~19,[6],[7]],
		"/gists": [20],
		"/gists/id": [21],
		"/gists/id/[gistId]": [22],
		"/id/[id]": [23],
		"/permaculture/books": [24],
		"/permaculture/books/[bookId]": [25],
		"/posts": [26],
		"/posts/project": [28],
		"/posts/[slug]": [27],
		"/projects": [29],
		"/quotes": [30],
		"/quotes/id": [32],
		"/quotes/id/[quoteId]": [33],
		"/quotes/[year]": [31],
		"/search": [34],
		"/search/by": [35],
		"/search/by/[author]": [36],
		"/search/tag/[tag]": [37],
		"/shop": [38],
		"/shop/garments": [39],
		"/sitemap": [40],
		"/slides": [41],
		"/slides/[postId]": [42],
		"/slides/[postId]/[index]": [43],
		"/swim": [~44],
		"/swim/[locationId]": [~45],
		"/[...post]": [9,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';